<template>
    <st-modal
        id="real-estate-modal"
        hide-header-delimiter
        hide-footer-delimiter
        hide-footer
        hideable
        :noCloseOnBackdrop="true"
        size="xl"
        ref="modal"
        customClass="form-modal"
    >
        <template #header>
            <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                {{ $t("REAL_ESTATE.MODAL.ADD_HEADER") }}
            </p>
        </template>
        <template #body>
            <realty-form
                :item="item"
                :cadastralNumber="cadastralNumber"
                @submitForm="submit"
                @cancelForm="cancel"
            ></realty-form>
        </template>
    </st-modal>
</template>

<script>
import RealtyForm from "@/modules/control/components/realty/RealtyForm";

export default {
    name: "RealtyModal",
    props: {
        item: {
            type: Object,
            required: false,
        },
        cadastralNumber: {
            type: Array
        }
    },
    components: {
        RealtyForm,
    },
    methods: {
        hide() {
            this.$refs["modal"].hide();
        },
        show() {
            this.$refs["modal"].show();
        },
        submit(data) {
            this.$emit("processRealty", data);
            this.hide();
        },
        cancel() {
            this.$emit("cancel");
            this.hide();
        },
    },
};
</script>
