<template>
    <form class="form" novalidate="novalidate" id="st_realty_details_form">
        <loader v-if="isSyncLoading" />
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.county_id.label }} *
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.county_id.name]"
                            :options="fields.county_id.options"
                            :ref="fields.county_id.name"
                            :name="fields.county_id.name"
                            :disabled="true"
                            :fields="fields"
                            :model="model"
                            :formValidation="fv"
                        >
                        </st-autocomplete>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.territorial_administrative_unit_id.label }} *
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.territorial_administrative_unit_id.name]"
                            :ref="fields.territorial_administrative_unit_id.name"
                            :name="fields.territorial_administrative_unit_id.name"
                            :options="fields.territorial_administrative_unit_id.options"
                            :disabled="viewMode"
                            :fields="fields"
                            :model="model"
                            :formValidation="fv"
                        >
                        </st-autocomplete>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.village_id.label }}
                            </label>
                            <div>
                                <st-autocomplete
                                    v-model="model[fields.village_id.name]"
                                    :options="fields.village_id.options"
                                    :ref="fields.village_id.name"
                                    :name="fields.village_id.name"
                                    :disabled="!fields.village_id.options.length || viewMode"
                                    :model="model"
                                    :fields="fields"
                                    :formValidation="fv"
                                >
                                </st-autocomplete>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.sector.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.sector.name"
                                    :name="fields.sector.name"
                                    :disabled="viewMode"
                                    v-model="model[fields.sector.name]"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.cadastral_number.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.cadastral_number.name"
                            :name="fields.cadastral_number.name"
                            v-model="model[fields.cadastral_number.name]"
                            :disabled="viewMode || !hasUatSelected"
                            @blur="syncAncpi"
                            enableBlur
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.street.label }} *
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.street.name"
                            :name="fields.street.name"
                            v-model="model[fields.street.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.street_number.label }} *
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.street_number.name"
                                    :name="fields.street_number.name"
                                    v-model="model[fields.street_number.name]"
                                    :disabled="viewMode"
                                    allow-only-alphanumeric
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="form-group">
                            <label>
                                {{ fields.staircase.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.staircase.name"
                                    :name="fields.staircase.name"
                                    v-model="model[fields.staircase.name]"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>
                                {{ fields.block.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.block.name"
                                    :name="fields.block.name"
                                    v-model="model[fields.block.name]"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.land_area.label }}
                    </label>
                    <b-row>
                        <b-col cols="10">
                            <st-input-text
                                :ref="fields.land_area.name"
                                :name="fields.land_area.name"
                                v-model="model[fields.land_area.name]"
                                :placeholder="$t('PLACEHOLDERS.MP')"
                                :disabled="true"
                            />
                        </b-col>
                        <b-col cols="2">
                            <st-button
                                variant="light"
                                customClass="text-primary shadow-sm p-3 mb-5 bg-white rounded"
                                :callback="syncAncpi"
                            >
                                <i class="fas fa-sync-alt text-primary"></i>
                            </st-button>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.construction_type.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.construction_type.name"
                            :name="fields.construction_type.name"
                            v-model="model[fields.construction_type.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.other_identification.label }}
                    </label>
                    <b-form-textarea
                        v-model="model[fields.other_identification.name]"
                        :ref="fields.other_identification.name"
                        :name="fields.other_identification.name"
                        :placeholder="fields.other_identification.placeholder"
                        rows="8"
                    ></b-form-textarea>
                </div>
            </div>
            <div class="col-12">
                <span v-if="searchStatus === 'notfound'" class="text-danger">{{ $t('CONTROL.NOTIFIERS.CADASTRAL_NUMBER_NOT_FOUND') }}</span>
                <span v-if="searchStatus === 'error'" class="text-danger">{{ $t('CONTROL.NOTIFIERS.CADASTRAL_NUMBER_ERROR') }}</span>
            </div>
        </div>
    </form>
</template>

<script>
import { RealtyModel } from "@/modules/control/models/realty-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { mapGetters, mapActions } from "vuex";
import { getCentroid } from '@/modules/atlas/utils/graphic-utils'

const { fields } = RealtyModel;

const formSchema = new FormSchema([
    fields.id,
    fields.cadastral_number,
    fields.county_id,
    fields.territorial_administrative_unit_id,
    fields.land_area,
    fields.village_id,
    fields.sector,
    fields.street,
    fields.street_number,
    fields.postal_code,
    fields.staircase,
    fields.block,
    fields.floor,
    fields.apartment,
    fields.land_area,
    fields.other_identification,
    fields.construction_type
]);

export default {
    name: "RealtyDetailsForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            documents: [],
            files: [],
            polling: null,
            searchStatus: '',
            isSyncLoading: false,
            fv: null,
        };
    },
    computed: {
        ...mapGetters({
            defaultCountyId: "shared/defaultCountyId",
            record: "control/form/record",
            loading: "shared/loading",
            parcels: "control/form/parcels",
        }),
        hasUatSelected() {
            return !!this.model[this.fields.territorial_administrative_unit_id.name];
        },
    },
    methods: {
        ...mapActions({
            searchParcel: 'control/form/searchParcel',
        }),
        async syncAncpi() {
            const { cadastral_number, territorial_administrative_unit_id } = this.model;
            if (cadastral_number && territorial_administrative_unit_id) {
                const payload = {
                    topo_number: cadastral_number,
                    siruta_code: territorial_administrative_unit_id,
                };
                try {
                    this.isSyncLoading = true;
                    const { data } = await this.searchParcel(payload);
                    this.searchStatus = data.status;
                    if (['pending', 'new'].includes(data.status)) {
                        this.polling = setInterval(() => {
                            this.checkSearchStatus(payload);
                        }, 1000);
                    } else if (data.status === 'completed') {
                        this.model[fields.land_area.name] = data.area;
                        this.model.category_code = data.category_code;
                        this.model.coordinates = data.coordinates;
                        this.model.centroid = getCentroid(data.coordinates);
                    } else if (['notfound', 'error'].includes(data.status)) {
                        this.model[fields.land_area.name] = '';
                    }
                } catch(error) {
                    this.model[fields.land_area.name] = '';
                    this.searchStatus = 'error';
                } finally {
                    if (this.searchStatus !== 'pending') {
                        this.isSyncLoading = false;
                    }
                }
            }
        },
        async checkSearchStatus(payload) {
            try {
                const { data } = await this.searchParcel(payload);
                if (data?.status) {
                    this.searchStatus = data.status;
                    if (this.searchStatus === 'completed') {
                        this.model[fields.land_area.name] = data.area;
                        this.model.category_code = data.category_code;
                        this.model.coordinates = data.coordinates;
                        this.model.centroid = getCentroid(data.coordinates);
                        clearInterval(this.polling);
                    } else if (['notfound', 'error'].includes(this.searchStatus)) {
                        this.model[fields.land_area.name] = '';
                        clearInterval(this.polling);
                    }
                }
            } catch(error) {
                this.model[fields.land_area.name] = '';
                this.searchStatus = 'error';
                clearInterval(this.polling);
            } finally {
                if (this.searchStatus !== 'pending') {
                    this.isSyncLoading = false;
                }
            }
        },
    },
    async created() {
        //hardcode Cluj as single option for county
        this.fields.county_id.options = this.fields.county_id.options.filter(
            (el) => {
                return el.value === this.defaultCountyId;
            }
        );
        if (this.item) {
            this.model = formSchema.initialValues(this.item);

            const parcelExists = this.parcels.find((el) => el.topo_number === this.item.cadastral_number);
            if (!parcelExists) {
                await this.syncAncpi();
            }
        } else {
            const record = {
                real_estate_type: "land",
                county_id: this.defaultCountyId,
            };

            this.model = formSchema.initialValues(record);
        }
    },
    watch: {
        'model.territorial_administrative_unit_id': function (newVal, oldVal) {
            if (oldVal) {
                this.model[fields.cadastral_number.name] = "";
                this.model[fields.land_area.name] = "";
            }
        },
        'model.village_id': function (newVal, oldVal) {
            if (oldVal) {
                this.model[fields.cadastral_number.name] = "";
                this.model[fields.land_area.name] = "";
            }
        },
    },
    mounted() {
        this.fv = createFormValidation("st_realty_details_form", this.rules);
    },
    beforeDestroy () {
        if (this.polling) {
            clearInterval(this.polling);
        }
    },
};
</script>
